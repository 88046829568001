
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BendStatusMetrics } from '@/models/deviceStatusMetrics';
import { getStateCss } from '@/utils/color';

@Component
export default class BendStatus extends Vue {
  @Prop({ required: true })
  private data!: BendStatusMetrics;

  private get stateStyle(): string {
    return this.data?.last_state ? getStateCss(this.data.last_state) : '';
  }
}
